.tezgah-container{
    /*height: 23rem;*/
    /*width: 24rem;*/
    max-width: 17rem;
    /* height: 15rem; */
    /*width: 15rem;*/
    width: 100%;
    /*border: 1px solid gray;*/
    border-radius: 22px;
    overflow: hidden;
    /*color: #46742C;*/
    /*margin-bottom: 1.5rem;*/
    /*box-shadow: 0 1px grey;*/
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.tezgah-background-img{
    border-radius: 14px;
    margin: 7px;
}
.stall-img-background{
    width: 100%;
    height: auto;
}
.stall-name-rate{
    margin-top: 6px;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 700;
}
.product-num-address{
    margin-top: 4px;
    font-size: 12px;
    text-align: right;

}
.see-all-products-btn{
    margin-top: 12px;
    width: 80%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}
.btn-full-width{
    width: 100%;
}
.stall-sub-container{
    padding: 0 8px;
}
.block_1 {
    width:100%;
    height:1px;
    margin-top: 0.5rem;
    background: #ebe0dc;
}