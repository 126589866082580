.blog-container {
    flex: 1 1;
    display: flex;
    align-items: center;
    padding: 0 50px;
    position: relative;
    /*width: 55%;*/
    overflow-x: hidden;
}
.cart-container{
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    min-width: 297px;
}
.blogs-inner{
    width: fit-content;
    /*max-width: 550px;*/
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    overflow-x: scroll;
    scroll-behavior: smooth;
}
.blogs-inner::-webkit-scrollbar {
    display: none;
}
.featured-box{
    background: #fff;
    border-radius: 15px;
    position: relative;
    z-index: 1;
    padding-bottom: 0em;
    margin-right: 1em;
    margin-left: 1em;
}
.bottom-left-arrow{
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    width: 3.5rem;
    height: 3.5rem;
    object-fit: contain;
    cursor: pointer;
}
.bottom-right-arrow{
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    width: 3.5rem;
    height: 3.5rem;
    object-fit: contain;
    cursor: pointer;
}
.featured-blog-title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
}
.featured-blog-description{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
}
@media only screen and (min-width: 801px) and (max-width: 1200px) {
    .cart-container {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .blogs-inner {
        width: 600px;
    }

}
@media only screen  and (max-width: 800px) {
    .cart-container {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .blogs-inner {
        max-width: 300px;
    }

}
@media only screen  and (max-width: 769px) {
    .cart-container {
        flex: 0 0 100%;
        max-width: 100%;
        min-width: unset!important;
    }
    .blogs-inner {
        max-width: 300px;
    }
}