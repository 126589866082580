.last-orders-inner{
    /*width: fit-content;*/
    /* max-width: 550
px
; */
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    overflow-x: scroll;
    scroll-behavior: smooth;
}
.horizontal-scrollable {
    flex: 1 1;
    /*display: flex;*/
    align-items: center;
    padding: 0 50px;
    position: relative;
    /* width: 55%; */
    overflow-x: hidden;
    border-radius: 0 12px 12px 0;
}
.last-orders-inner::-webkit-scrollbar{
    width: 10px;
    height: 5px;
}
.last-orders-inner::-webkit-scrollbar-track{
    background: #d7e5ba;
    border: 4px solid white; /* Use your background color instead of White */
    background-clip: content-box;
    margin-top: 15px;
    margin-bottom: 15px;
}
.last-orders-inner::-webkit-scrollbar-thumb{
    background: #90b437;
    border-radius: 9px;
}

@media (max-width: 991px) {
    .horizontal-scrollable{
        border-radius: 0;
    }
}