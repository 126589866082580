.blog-footer-title{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    color: #84B700;
    font-size: 18px;
    font-weight: 600;
    height: 20px;
    margin: 0 12px;
}
.blog-footer-content{
    color: #46742C;
    font-size: 14px;
    line-height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin: 6px 12px;
}
.blog-footer-btn{
    color: #FD7922;
    margin: 0 12px;
    font-size: 16px;
    font-weight: 700;
}
.blog-footer-btn:hover{
    color: #FF9C5A;
    cursor: pointer;
}

.first-blog-content{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 11;
    -webkit-box-orient: vertical;
}

.second-blog-content{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
}

.all-blog-continue{
    color: #80BC00;
    font-weight: 700;
}

