.overlay-new{
    z-index: 0;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(70,70,70, 0.6);
    /*background: linear-gradient(90deg, rgba(70,116,44,1) 30%, rgba(255,255,255,0) 90%);*/
    opacity: 0.7;
}
.main-title{
    font-size: 2.4rem;
}
.slider-item-2{
    color: rgba(70,116,44,1);
    min-height: 500px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    display: flex;
    flex-direction: column;
}
.main-title-2{
    font-size: 22px;
    font-weight: bold;
    font-family: 'Open Sans', sans-serif;
    margin: 3rem auto;
    height: 2rem;
}
.sub-container{
    width: 16.666667%;
    height: 100%;
    display: flex;
    flex-direction: column;


}
.main-2-container{
    width: 100%;
    height: 100%;
    display: flex;
}
.dot-main{
    height: 160px;
    width: 160px;
    background-color: #90B437;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: auto;
    margin-left: auto;
}
.dot-img1{
    height: 4rem;
    width: auto;
}
.dot-img2{
    height: 4rem;
    width: auto;
}
.dot-img3{
    height: 4rem;
    width: auto;
}
.dot-img4{
    height: 4rem;
    width: auto;
}
.text-2{
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    /*font-weight: bolder;*/
    margin-right: 1rem;
    margin-left: 1rem;
    margin-top: 2rem;
    display: block;
    text-align: center;
}
.home-select-market{
    border-radius: 16px;
    background: white;
    max-width: 22rem!important;
    height: 22rem;
    margin-top: 5.5rem;
    padding: 0 1.2rem!important;
}
.select-title{
    font-weight: bolder;
    font-size: 18px;
    margin-top: 18px;
    margin-bottom: 4px;
    color: #1c7430;
    text-align: center;

}
.new-dropdown{
    background: #90B52A;
    border-radius: 12px;
    margin-top: 1rem;
    padding-left: 10px;
}
.new-dropdown a.dropdown-toggle{
    color: white!important;
}
.new-dropdown .dropdown-toggle::after {
    border-color: white!important;
    margin-left: 0;
    right: 20px;
    margin-top: 7px;
}

.show-stalls-btn .btn-secondary {
    margin-top: 1rem;
    width: 100%;
   padding: 10px 10px;
}
.show-stalls-btn-header .btn-secondary{
    margin-left: 1rem;
}
.show-basket-btn-header .btn-secondary{
    background: #90B52A!important;
    border-color: #1c7430!important;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    /*margin-left: 28px;*/
    margin-left: 5px;
}
.show-basket-btn-header .btn-secondary img{
    height: 1.8rem;
    width: auto;
    margin-right: 1rem;
}

.show-basket-btn-header .btn-secondary:hover {
    color: #fff;
    background-color: #bce648;
    border-color: #1c7430;;
}
.sub-header-content{
    justify-content: space-between;
}
@media only screen and (max-width: 1200px) {
    .show-basket-btn-header .btn-secondary{
        margin-left: 0;
    }
    .show-basket-btn-header{
        padding-left: 0!important;
    }
}