@font-face {
    font-family: 'blurmedium';
    src: url('../fonts/blur/blur_medium-webfont.woff2') format('woff2'),
    url('../fonts/blur/blur_medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input:-webkit-autofill{
    -webkit-text-fill-color: #90B52A !important;
}


body {
    font-family: 'Open Sans', sans-serif;
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
    border: 0;
    outline: none;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.header {
    background: #90B437;
    padding: 20px 0;
}

.logo {
    background: url(../images/pazardan-logo.svg) no-repeat center;
    background-size: contain;
    width: 210px;
    height: 66px;
    display: block;
}
.pay-tr-iframe {
    overflow-x: hidden !important;
}
.order-summary-page{
    padding-bottom: 2em;
}
.left-stalls-column{
    padding-left: 0;
    padding-right: 0;
}

.img-fluid-2{
    width: 100%;
    max-width: 16rem;
    margin-left: auto;
    margin-right: auto;
}


.total-cart{
    height: 2.9rem;
}
@media (max-width: 575.98px) {
    .left-stalls-column {
        padding-left: 15px;
        padding-right: 15px;
    }
}
@media (min-width: 576px) and (max-width: 768px) {
    .left-stalls-column {
        padding-left: 20px;
        padding-right: 20px;
    }
}
@media screen and (max-width: 768px) {
    .mobile-l-icons{
        display: flex;
        flex-direction: column;
    }
    .profile-page {
        padding: 1em 0;
    }
    .mobile-s-icons{
        margin-bottom: 2rem;
    }
    .download-icons-mobile a{
        margin-left: auto;
        margin-right: auto;
    }
    .socials{
        align-self: center;
    }
    .seller-btn{
        align-self: center;
    }
    .pazardan-main{
        margin-top: 60px;
    }
    .home-select-market{
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 4rem;
        /* display: none; */
    }
    .logo {
        background: url("../images/pazardan-logo-mobile.svg") no-repeat center;
        width: 130px;
        height: 50px;
        margin: 0 auto;
    }
    .show-stalls-btn-header{
        display: none;
    }
    .show-basket-btn-header{
        display: none;
    }
    .all-basket-mobile-hide{
        max-height: 130px!important;
    }
    .basket-show-mobile {
        display: block!important;
        margin-top: 3rem;
        padding-left: 1rem;
        padding-right: 1rem;
        margin-bottom: 1rem;
    }
    .basket-show-mobile .total-cart {
        display: none;
    }
    .basket-show-mobile .total-mobile {
        display: none;
    }
}

.nav-toggle {
    width: 25px;
    height: 25px;
    cursor: pointer;
    text-decoration: none;
}

.nav-toggle.active i::before, .nav-toggle.active i::after {
    background: #444;
}

.nav-toggle:hover, .nav-toggle:focus, .nav-toggle:active {
    outline: none;
    border-bottom: none !important;
}

.nav-toggle i {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 2px;
    color: #fff;
    font: bold 14px/.4 Helvetica;
    text-transform: uppercase;
    text-indent: -55px;
    background: #fff;
    transition: all .2s ease-out;
}

.nav-toggle i::before, .nav-toggle i::after {
    content: '';
    width: 20px;
    height: 2px;
    background: #252525;
    position: absolute;
    left: 0;
    transition: all .2s ease-out;
}

.nav-toggle i::after {
    width: 12px;
}

.nav-toggle > i {
    color: #fff;
    background: #fff;
}

.nav-toggle > i::before, .nav-toggle > i::after {
    background: #fff;
}

.nav-toggle i::before {
    top: -7px;
}

.nav-toggle i::after {
    bottom: -7px;
}

.nav-toggle:hover i::before {
    top: -10px;
}

.nav-toggle:hover i::after {
    bottom: -10px;
}

.nav-toggle.active i {
    background: transparent;
}

.nav-toggle.active i::before {
    background: #fff;
    top: 0;
    -webkit-transform: rotateZ(45deg);
    -moz-transform: rotateZ(45deg);
    -ms-transform: rotateZ(45deg);
    -o-transform: rotateZ(45deg);
    transform: rotateZ(45deg);
}

.nav-toggle.active i::after {
    background: #fff;
    bottom: 0;
    -webkit-transform: rotateZ(-45deg);
    -moz-transform: rotateZ(-45deg);
    -ms-transform: rotateZ(-45deg);
    -o-transform: rotateZ(-45deg);
    transform: rotateZ(-45deg);
    width: 19px;
}

.nav-toggle {
    position: absolute;
    left: 15px;
    /* top: 10px; */
    padding: 6px 0 0 0;
    margin: 0 auto;
    display: none;
    height: 36px;
    width: 44px;
    z-index: 2001;
    border-bottom: none !important;
}

.search {
    background: url("../images/search.svg") no-repeat center;
    width: 18px;
    height: 18px;
    position: relative;
    right: 15px;
    top: 17px;
    display: none;
}

.dropdown-toggle {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    width: 100%;
}

.dropdown-toggle:hover {
    color: #fff;
}

.header-dropdown {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0.5rem 1rem;
}

@media screen and (min-width: 769px) {
    .menu-mobile {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .menu-mobile{
        width: 100%;
        padding: 12px;
        /*background: white;*/
        position: absolute;
        left: 0;
        top: 70px;
        background-color: #36751d;
    }

    .menu-mobile .dropdown-item{
        text-align: center;
        border-bottom-color: #abdde5;
    }

    .menu-mobile a{
        color: white;
        font-size: 16px;
        font-weight: bolder;
    }

    .addresses {
        display: block !important;
    }

    .nav-toggle {
        display: block;
    }

    .hidden{
        display: none;
    }

    .navbar, .header-dropdown {
        display: none;
    }

    .profile-navbar {
        width: 100%;
    }

    .search {
        display: block;
    }
}

.dark-green {
    background: #46742c;
}

.dark-green .btn-primary {
    background: #46742c;
}

.pazardan-main {
    position: relative;
}

.pazardan-main .text-white{
    margin-top: 4rem;
    padding-left: 0;
}

.main-slider {
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.overlay {
    z-index: 0;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    background: rgb(70, 116, 44);
    background: linear-gradient(90deg, rgba(70, 116, 44, 1) 30%, rgba(255, 255, 255, 0) 90%);
    opacity: 0.7;
}

.slider-item {
    /*height: 500px;*/
    min-height: 550px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
}

.slider-inner-text {
    /*display: table-cell;*/
    vertical-align: middle;
    min-height: 300px;
    position: relative;
    z-index: 2;
    padding: 2em 0;
    color: #fff;
    display: flex;
}

.slider-inner-container {
    display: inline-table;
    opacity: 1;
    min-height: 500px;
    margin: 0 auto;
    width: 100%;
}

.slider-inner-text h1 {
    font-size: 68px;
    font-weight: 500;
    font-family: 'blurmedium';
}

.slider-inner-text p {
    font-size: 18px;
}

@media screen and (max-width: 768px) {
    .slider-inner-text h1 {
        font-size: 35px;
    }

    .slider-inner-text p {
        font-size: 12px;
    }
}

i.navbar-signup {
    background: url(../images/profile-white.svg) no-repeat center;
    width: 15px;
    height: 15px;
    display: inline-block;
    background-size: contain;
    margin-right: 9px;
}

.google-map {
    min-height: 400px;
    width: 100%;
    background-size: cover;
    position: relative;
}

.featured-blog {
    padding-top: 5em;
    overflow: hidden;
    margin-bottom: 5rem;
}

.featured-blog h1, .contact-us h1 {
    font-size: 22px;
    font-weight: bold;
    color: #46742C;
    line-height: 1.2em;
}

.featured-blog-box {
    position: relative;
    z-index: 1;
}

.featured-box {
    background: #fff;
    border-radius: 15px;
    position: relative;
    z-index: 1;
    padding-bottom: 0em;
    /*margin-right: 2em;*/
    overflow: hidden;

}

@media screen and (max-width: 768px) {
    .featured-box {
        /*margin-right: 15px;*/
        margin-right: 1em;
    }

    .featured-blog .container {
        padding-left: 0;
        padding-right: 0;
    }

    .navbar {
        margin: 0 auto;
    }

    .header {
        position: fixed;
        top: 0;
        min-height: 60px;
        width: 100%;
        padding: 10px 0px;
        z-index: 999;
    }

    .sub-header {
        position: relative;
        top: 70px;
        width: 100%;
        margin-bottom: 40px;
    }
}

.featured-box .featured-image {
    min-height: 180px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    /*min-width: 17.96rem;*/
    margin-bottom: 0em;
    padding: 0;
    border-radius: 15px;
}

.featured-box h2 {
    font-weight: 600;
    font-size: 28px;
    color: #84B700;
}

.featured-box p {
    font-size: 16px;
    color: #46742C;
}

.featured-box .container {
    padding-right: 1em;
    padding-left: 1em;
}

.btn-featured {
    border: 1px solid #FD7922;
    font-size: 18px;
    color: #FD7922;
    width: 100%;
    padding: 10px 5px;
    margin: 0 auto;
}

.btn-featured:hover {
    background: #FD7922;
    border-color: #FD7922;
    color: #fff;
}

.blog-bottom {
    min-height: 600px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    top: -10em;
    z-index: 0;
}

.contact-us {
    position: relative;
    top: -5em;
}

.form-group > label, .custom-select_ label {
    position: absolute;
    top: -8px;
    left: 30px;
    background-color: white;
    color: #46742C;
    padding-left: 0.5em;
    padding-right: 0.5em;
    font-size: 14px;
    font-weight: 600;
}

.form-group {
    position: relative;
    border-radius: 15px;
    margin-bottom: 2.2em;
}

.needs-validation .form-group{
    border: 1px solid #90b52a;
}
.form-group > input {
    border: none;
    color: #90B52A;
    font-size: 14px;
    /*border: 1px solid #90b52a;*/
    border-radius: 15px;
}
.form-group > select {
    border: none;
    color: #90B52A;
    font-size: 14px;
    border: 1px solid #90b52a;
    border-radius: 12px;
}

.form-group > textarea {
    border: 0;
    resize: none;
    outline: none;
    font-size: 14px;
    color: #84b700;
    /*border: 1px solid #90b52a;*/
    border-radius: 15px;
    min-height: 100px;
    padding-top: 20px;
}


.form-check {
    border: 0;
}

.form-check label {
    font-size: 12px;
    color: #90B52A;
}

.footer {
    background: #F7F7F7;
    padding: 3em 0;
}

ul.footer-menu {
    margin: 0;
    padding: 0;
    list-style: none;
}

ul.footer-menu li a {
    line-height: 1.9em;
    font-size: 14px;
    font-weight: normal;
    color: #46742C;
}

.footer p {
    color: #46742C;
    font-size: 16px;
    font-weight: 600;
}

ul.socials {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: right;
}

ul.socials li {
    display: inline-block;
    margin-right: 1em;
}

.copyright p {
    font-size: 10px;
    color: #46742C;
    text-align: center;
    margin: 1.2em 0;
}

.seller-btn {
    text-align: right;
}

.tr-carousel{
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 30px;
}
.tr-carousel > div > div {
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
div[data-arrow="right"] {
    position: absolute;
    right: 0;
}

@media screen and (max-width: 768px) {
    .footer p, .footer a, ul.footer-menu, ul.socials, .seller-btn {
        text-align: center;
    }

    ul.footer-menu {
        margin-top: 2em;
        margin-bottom: 2em;
    }
}

.pazardan-banner h1 {
    font-size: 38px;
}

.pazardan-banner p {
    font-size: 18px;
}

.pazardan-banner {
    background-color: #9ece53;
    height: 395px;
    color: #fff;
    background-image: url(../images/seller-banner.png);
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    background-position: top right;
}

.banner-inner-text {
    display: table-cell;
    vertical-align: middle;
    min-height: 300px;
    position: relative;
    z-index: 2;
    padding: 2em 0;
    color: #fff;
}

.pazardan-banner .container {
    display: table;
    height: 100%;
}

.seller-information {
    background: #f7f7f7;
    padding: 5em 0;
    color: #46742c;
    position: relative;
}

.seller-information h2 {
    font-size: 28px;
    font-weight: 600;
    /* margin-bottom: 1em; */
}

.seller-information p {
    font-size: 18px;
    line-height: 1.5em;
    margin-bottom: 2em;
}

.seller-information .phone {
    background-image: url(../images/phone.png);
    width: 100%;
    height: 450px;
    background-size: cover;
    position: absolute;
    top: -12em;
}

.t-0 {
    top: 0;
}

.seller-page-form, .markets {
    padding: 3em 0 5em 0;
}

@media screen and (max-width: 768px) {
    .pazardan-banner h1 {
        font-size: 25px;
        margin-top: 2em;
    }

    .pazardan-banner p {
        font-size: 12px;
    }

    .pazardan-banner .container {
        padding-right: 15px !important;
        padding-left: 15px !important;
    }

    .pazardan-banner {
        background-size: cover;
        background-position: bottom;
    }

    .seller-information .phone {
        top: -5em;
        position: relative;
        height: 350px;
    }

    .seller-information {
        padding-top: 0;
    }

    .seller-text {
        margin-top: -5em;
    }
}

.all-products, .stalls, .all-basket {
    border-radius: 25px;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.16);
    height: 100%;
    padding: 1.5em 0.2em;
    height: auto;
    min-height: 430px;
}
.search-input{
    border-radius: 15px;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.16);
    height: auto;
}

.search-input input{
    border-radius:25px;
    color: #90b52a;
}
.search-input span{
    height: 24px !important;

}
.has-search .form-control {
    padding-left: 2.375rem;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}

.markets h1, .all-basket h2, .stalls h2,  #myOrders .order-basket h2 {
    text-align: center;
    color: #4e7231;
    font-size: 25px;
    margin-bottom: 1em;
    font-weight: bold;
}

.all-basket h2, #myOrders .order-basket h2 {
    position: relative;
}

.all-basket h2::after, .basket::after, .user-info h1::after,  #myOrders .order-basket h2:after {
    content: " ";
    position: absolute;
    background: rgba(70, 116, 44, 0.15);
    height: 1px;
    width: 80%;
    bottom: -0.5em;
    left: 10%;
    right: 0;
}
.sss::after {
    content: " ";
    position: absolute;
    /*background: rgba(70, 116, 44, 0.15);*/
    height: 1px;
    width: 80%;
    bottom: -0.5em;
    left: 0;
    right: 0;
}

.all-basket h2 span.clear-basket {
    text-align: right;
    display: inline-block;
    font-size: 14px;
    position: absolute;
    right: 8px;
    top: 10px;
    cursor: pointer;
    font-weight: 200;
}
.m-container .addbasket{
    max-height: 370px;
}

.addbasket {
    max-height: 750px;
    overflow-y: auto;
}

.empty-products {
    display: none;
}

.empty-products p {
    text-align: center;
    color: #46742c;
    font-size: 16px;
}

.empty-products img {
    margin: 0 auto;
    height: 120px;
    display: block;
    margin-bottom: 1.2em;
}

.market-image {
    border-radius: 17px;
    height: 190px;
    width: 100%;
    /* margin-bottom: 1em; */
    background-size: cover;
}

.sub-header {
    background-color: #46742c;
    height: 70px;
    color: #fff;
    padding: 0.7em 0;
    box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.38);
}

.sub-header h1 {
    font-size: 18px;
    font-weight: bold;
    text-indent: 10px;
}

.sub-header .row {
    min-height: 45px;
}

.min-price {
    font-size: 16px;
    vertical-align: middle;
    font-weight: 600;
}

.min-price span {
    color: #84b700;
}

.category-dot {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    width: 25px;
    color: white;
    background-color: #90b437;
    border-radius: 50%;
    /*display: inline-block;*/
    font-size: 10px;
    font-weight: bold;
}

.active .category-dot {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    width: 25px;
    color: white;
    background-color: #46742c;
    border-radius: 50%;
    /*display: inline-block;*/
}

.product-categories .category {
    border-radius: 12px;
    border: solid 2px #90b437;
    /*display: inline;*/
    padding: 0.6em 2.2em;
    text-transform: uppercase;
    /*width: 23%;*/
    /*width: 7rem;*/
    /*max-width: 7rem;*/
    /* width: 100%; */
    /*margin: 0 0.5em;*/
    text-align: center;
    color: #84b700;
    text-decoration: none;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.product-categories .category:hover, .product-categories .category.active {
    background: #84b700;
    color: #fff;
}

.product-categories .category.disable {
    color: rgba(144, 180, 55, 0.3);
    border-color: rgba(144, 180, 55, 0.3);
    pointer-events: none;
}

.product-categories .category.disable:hover {
    background: #fff;
}

.market-name {
    font-size: 32px;
    color: #46742c;
    font-weight: bold;
    padding-bottom: 0.4em;
}

.market-info {
    font-size: 19px;
    color: #90b52a;
    font-weight: bold;
    margin-top: 0.9em;
    margin-bottom: 0.5em;
}

.modal-body .market-info {
    font-size: 12px;
}

.market {
    position: relative;
    margin-bottom: 2em;
    background: #fff;
    padding: 0.7em 0.5em;
    cursor: pointer;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

/*.market::after{
	content: " ";
	position: absolute;
	background-color: rgba(132, 183, 0, 0.2);
	height: 1px;
	bottom: -0.5em;
	left: 0;
	right: 0;
}
.market:last-child::after,.sss:last-child::after{
	display: none;
	margin:0;
	}*/
.market:last-child {
    margin-bottom: 0;
}

/*	.market.active, .market:hover {
		background: #84b700;
		border-radius: 24px;

		}*/
/*.market.active .market-name, .market.active .market-info,.market:hover .market-name, .market:hover .market-info,.market:hover .rating i.points {
    color: #fff;
}
.market:hover .rating span::after,.market:hover .rating span::before{
    background:#fff;
}
.market:hover .rating span.grey::after, .market:hover .rating span.grey::before{
    background: #b1b1b1;
    }*/
@media screen and (max-width: 768px) {
    /*.all-markets {
        min-height: 320px !important;
        margin-bottom: 1em;
        border: none;
        box-shadow: none;
        padding: 0;
        }*/
    /* .product-categories .category {
        width: 45%;
    } */
    .all-products {
        margin-bottom: 1.5em;
        border: none;
        box-shadow: none;
        padding: 0;
    }

    .market::after {
        display: none;
    }

    .market {
        margin-bottom: 0;
    }
}

.products .name {
    color: #84b700;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0.5em;
    min-height: 40px;
}

.products .price {
    color: #46742c;
    font-size: 16px;
    text-align: center;
    font-weight: bold;
    text-transform: capitalize;
}

.products img {
    display: block;
    margin: 0 auto;
    margin-bottom: 0.5em;
    cursor: pointer;
}

.products {
    border-right: 1px solid rgb(70, 116, 44, 0.15);
    margin-top: 1em;
}

.products:last-child {
    border: none;
}

.products {
    border-right: 1px solid rgb(70, 116, 44, 0.15);
    margin-top: 1em;
    margin-bottom: 1em;
    padding-bottom: 1em;
}

.products::after {
    content: " ";
    position: absolute;
    background: rgba(70, 116, 44, 0.15);
    height: 1px;
    bottom: -1em;
    left: 0;
    right: 0;
}

.all-products {
    position: relative;
    max-height: 90vh;
    overflow-y: scroll;
    overflow-x: hidden;
}
.all-products::-webkit-scrollbar{
    width: 10px;
}
.all-products::-webkit-scrollbar-track{
    background: #d7e5ba;
    border: 4px solid white; /* Use your background color instead of White */
    background-clip: content-box;
    margin-top: 15px;
    margin-bottom: 15px;
}
.all-products::-webkit-scrollbar-thumb{
    background: #90b437;
    border-radius: 9px;
}
.all-products .bottom {
    background-color: #fff;
    height: 5px;
    width: 90%;
    bottom: 20px;
    position: absolute;
}

.all-products .right {
    background-color: #fff;
    height: 80%;
    width: 10px;
    right: 10px;
    position: absolute;
}

.basket img {
    display: block;
    margin-top: 0.8em;
}

.basket .name {
    font-size: 14px;
    color: #46742c;
    font-weight: 600;
    margin-bottom: 0.7em;
}

.basket .size {
    color: #90b52a;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0.3em;
    text-transform: capitalize;
}

.basket .price {
    color: #90b52a;
    font-size: 16px;
    font-weight: bold;
}

.basket .edit {
    background: url(../images/edit.svg) no-repeat center;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 0;
    cursor: pointer;
}

.basket .trash {
    background: url(../images/trash.svg) no-repeat center;
    width: 20px;
    height: 20px;
    position: absolute;
    bottom: 0;
    cursor: pointer;
}

.basket {
    margin-top: 1.5em;
    position: relative;
    margin-bottom: 1em;
    padding-bottom: 1em;
}

.basket-sum p {
    font-size: 22px;
    color: #46742c;
    text-align: center;
    margin-bottom: 0.1em;
    font-weight: bold;
    margin-top: 1.4em;
}

.basket-sum span {
    color: #90b52a;
    font-size: 24px;
    font-weight: bold;
    display: block;
}

.basket-sum {
    text-align: center;
}

.basket-sum .btn-secondary {
    width: 90%;
    margin-top: 1em;
}

.order-page {
    padding: 5em 0;
}

.order-message {
    border-radius: 25px;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.16);
    text-align: center;
    padding: 2em 1em;
}

.order-message h1 {
    color: #46742c;
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 30px;
}

.order-message h2 {
    color: #84b700;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
}

.order-message p {
    font-size: 16px;
    color: #46742c;
}

.order-summary .sum {
    color: #46742c;
    font-size: 16px;
    font-weight: bold;
}

.order-summary h1 {
    font-size: 18px;
    color: #46742c;
    font-weight: bold;
    margin-bottom: 1.5em;
}

.order-summary .price {
    text-align: right;
    display: inline-block;
    color: #90b52a;
    font-weight: normal;
    font-size: 18px;
}

.order-summary .total-price {
    font-size: 30px;
    font-weight: bold;
    color: #90b52a;
    text-align: right;
}

.order-summary .cargo-free {
    display: inline-block;
    text-align: right;
    color: #fd7924;
    font-size: 14px;
    text-decoration: underline;
    margin-right: 10px;
}

.cargo-fee {
    text-decoration: line-through;
    font-size: 18px;
}

.order-summary .total {
    color: #46742c;
    font-weight: bold;
    font-size: 22px;
}

/*.order-summary::after {
	content: " ";
	position: absolute;
	bottom: -1em;
	width: 98%;
	height: 1px;
	background-color: #90b52a;
	opacity: 0.1;
	}*/
.order-summary {
    position: relative;
}

.address-summary h2 {
    font-size: 16px;
    color: #46742c;
    font-weight: bold;
}

.address-summary p {
    font-size: 14px;
    color: #90b52a;
}

.address-summary .name, .address-summary .detail {
    font-weight: 600;
}

.border-line {
    background-color: rgba(70, 116, 44, 0.15);
    height: 1px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 1em;
}

.address-summary a.btn.btn-secondary {
    margin: 2.3em auto;
}

.address-summary .name {
    font-weight: normal;
}

.box {
    border-radius: 25px;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.16);
    padding: 0.7em 0.9em 0.7em 0.9em;
    margin-bottom: 2em;
    margin-top: 2em;
    background: #fff;
}

.profile-page {
    padding: 1em 0;
}

.user-info.box h1 {
    font-size: 22px;
    font-weight: bold;
    color: #46742c;
    position: relative;
    margin-bottom: 1.3em;
}

.user-info.box p {
    font-size: 16px;
    font-weight: 600;
    color: #84b700;
}

ul.profile-items {
    margin: 0;
    padding: 0em 0.8em 0em 0em;
    list-style: none;
    font-size: 16px;
}

ul.profile-items li a {
    color: #84b700;
    font-size: 16px;
    border-bottom: solid 1px rgba(70, 116, 44, 0.15);
    /* margin-bottom: 2em; */
    display: block;
    padding-bottom: 0.8em;
}

ul.profile-items li a.active {
    color: #fd7922;
}

ul.profile-items li {
    margin-bottom: 0.8em;
}

ul.profile-items li:last-child a {
    border: none;
    padding-bottom: 0;
}

.menu-icons {
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-right: 15px;
    vertical-align: bottom;
}

.overflow-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

i.mail-icon {
    background: url(../images/mail.svg) no-repeat center;
    background-size: contain;
}

i.phone-icon {
    background: url(../images/phone.svg) no-repeat center;
    background-size: contain;
}

i.address-icon {
    background: url(../images/menu-pin.svg) no-repeat center;
    background-size: contain;
}
i.address-icon-white{
    background: url(../images/menu-pin-white.svg) no-repeat center;
    background-size: contain;
}

ul.profile-items li a.active .address-icon {
    background-image: url(../images/menu-pin-orange.svg);
}

i.order-icon {
    background: url(../images/order.svg) no-repeat center;
    background-size: contain;
}
i.order-icon-white {
    background: url(../images/order-white.svg) no-repeat center;
    background-size: contain;
}

ul.profile-items li a.active i.order-icon {
    background-image: url(../images/order-orange.svg);
    background-size: contain;
}

i.profile-icon {
    background: url(../images/profile.svg) no-repeat center;
    background-size: contain;
}
i.profile-icon-white {
    background: url(../images/profile-white.svg) no-repeat center;
    background-size: contain;
}
i.add-profile-icon-white {
    background: url(../images/add-profile-white.svg) no-repeat center;
    background-size: contain;
    height: 28px;
    width: 28px;
    margin-left: -6px;
}

ul.profile-items li a.active i.profile-icon {
    background-image: url(../images/profile-orange.svg);
    background-size: contain;
}

i.lock-icon {
    background: url(../images/lock.svg) no-repeat center;
    background-size: contain;
}
i.lock-icon-white {
    background: url(../images/lock-white.svg) no-repeat center;
    background-size: contain;
}

ul.profile-items li a.active i.lock-icon {
    background-image: url(../images/lock-orange.svg);
    background-size: contain;
}

i.contact-icon {
    background: url(../images/contact.svg) no-repeat center;
    background-size: contain;
}
i.contact-icon-white {
    background: url(../images/contact-white.svg) no-repeat center;
    background-size: contain;
}

ul.profile-items li a.active i.contact-icon {
    background-image: url(../images/contact-orange.svg);
    background-size: contain;
}

i.sss-icon {
    background: url(../images/question.svg) no-repeat center;
    background-size: contain;
}
i.logout-icon {
    background: url(../images/logouticon.svg) no-repeat center;
    background-size: contain;
}
i.logout-icon-white {
    background: url(../images/logouticon-white.svg) no-repeat center;
    background-size: contain;
}
.green-search{
    background: url(../images/search-green.svg) no-repeat center;
    background-size: contain;
    height: 100%!important;

}
.green-clearX{
    background: url(../images/xmark.svg) no-repeat center;
    background-size: contain;
    height: 100%!important;
}
i.sss-icon-white {
    background: url(../images/question-white.svg) no-repeat center;
    background-size: contain;
}

ul.profile-items li a.active i.sss-icon {
    background-image: url(../images/question-orange.svg);
    background-size: contain;
}

i.support-icon {
    background: url(../images/support.svg) no-repeat center;
    background-size: contain;
}

ul.profile-items li a.active i.support-icon {
    background-image: url(../images/support-orange.svg);
    background-size: contain;
}

.address-details h1 {
    font-size: 18px;
    font-weight: bold;
    color: #46742c;
}
#login-pass::placeholder {
    opacity: 0.5;
}
#login-pass:focus::placeholder{
    color: transparent;
}
.address-details p {
    font-size: 16px;
    font-weight: 600;
    color: #90b52a;
}

.address-edit a {
    display: block;
}

.address-edit .selected {
    background: url(../images/checkbox.svg) no-repeat center;
    width: 20px;
    height: 20px;
    background-size: contain;
}

.address-edit .selected.active {
    background: url(../images/checkbox-active.svg) no-repeat center;
    width: 30px;
    height: 30px;
    background-size: contain;
}

.address-edit .edit {
    background: url(../images/edit.svg) no-repeat center;
    width: 20px;
    height: 20px;
    background-size: contain;
    margin-top: 3em;
}

.address-edit .trash {
    margin-top: 3em;
    background: url(../images/trash.svg) no-repeat center;
    width: 20px;
    height: 20px;
    background-size: contain;
}
button[data-direction] {
    top: calc(40% - 17px);
    width: 50px;
    height: 50px;
    display: block;
    cursor: pointer;
    border: none;
    position: absolute;
    background-color: red;
}
a.more:hover .more-btn {
    background-color: #FF9C5A;
}
.btn-primary:hover{
    border-color: #A6C35F;
    background-color: #A6C35F;
}
.address-add .btn-primary:hover{
    border-color: #A6C35F;
    background-color: #fff;
}

.address-add .select-selected {
    color: #A6C35F;
}
.address-add .btn-primary.dropdown-toggle {
    background-color: #fff!important;
}

.more-btn {
    background-color: #ea7500;
    width: 60px;
    height: 60px;
    margin: 0 auto;
    display: block;
    border-radius: 50px;
    position: relative;
}

.more-btn i {
    display: block;
    background: url(../images/plus.svg) no-repeat center;
    width: 16px;
    height: 16px;
    position: absolute;
    top: 22px;
    left: 23px;
}

.more-btn-text {
    display: block;
    font-size: 12px;
    font-weight: 600;
    color: #46742c;
    margin-top: 9px;
}

.address-form {
    margin-top: 2em;
}
/*the container must be positioned relative:*/
.custom-select_ {
    position: relative;
    border: 0;
    background: #fff;
}

/*.custom-select_ select {
    display: none; !*hide original SELECT element:*!
}*/

.select-selected {
    background-color: white;
}

/*style the arrow inside the select element:*/
.select-selected:after {
    position: absolute;
    content: "";
    top: 22px;
    right: 16px;
    width: 10px;
    height: 10px;
    /* border: 6px solid transparent; */
    /* border-color: #90b52a transparent transparent transparent; */
    border-left: 2px solid #90b52a;
    border-bottom: 2px solid #9abc40;
    transform: rotate(-45deg);
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

/*point the arrow upwards when the select box is open (active):*/
.select-selected.select-arrow-active:after {
    /* border-color: transparent transparent #90b52a transparent; */
    top: 22px;
    transform: rotate(135deg);
}

/*style the items (options), including the selected item:*/
.select-selected {
    color: #83b703;
    padding: 15px 40px;
    /*border: 1px solid #90B52A;*/
    /* border-color: #90b52a #90b52a transparent #90b52a; */
    cursor: pointer;
    user-select: none;
    margin: 0;
    border-radius: 15px;
    font-size: 14px;
    min-height: 54px;
}


.seller-page-form .form-check{
    border: none;
}

/*style items (options):*/
.select-items {
    position: absolute;
    background-color: white;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
    margin-top: 0;
    border-radius: 15px;
    /* border: 1px solid #90b52a; */
    color: #90b52a;
    max-height: 160px;
    overflow-y: auto;
    box-shadow: 0 0 16px 0 rgb(0 0 0 / 16%);
    margin-top: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
}

/*hide the items when the select box is closed:*/
.select-hide {
    display: none;
}

.select-items div:hover, .same-as-selected {
    /* border-bottom: 1px solid #90b52a; */
    /* border-radius: 14px 14px 0 0; */
    /* color: #fff; */
    /* background: #90b52a; */
    font-weight: 600;
    cursor: pointer;
}

.select-items div {
    /* border-bottom: 1px solid #90b52a; */
    /* border-radius: 14px; */
    padding: 5px 15px;
}

.select-items div:last-child {
    border-bottom: 0;
    border-radius: 0 0 14px 14px;
}

.select-items div:last-child:hover {
    border-radius: 0 0 14px 14px;
}

.select-items div:first-child:hover {
    /* border-radius: 14px 14px 0 0; */
}

.select-items .same-as-selected {
    color: #fff;
    background: #90b437;
}

.select-items div:first-child {
    /* border-radius: 14px 14px 0 0; */
}

.cities .select-selected::before {
    content: " ";
    position: absolute;
    background: url(../images/pin-dark-green.svg) no-repeat center;
    width: 20px;
    height: 20px;
    left: 12px;
    display: block;
    background-size: cover;
}

.was-validated :invalid ~ .select-selected {
    border-color: #ee6560;
    color: #ee6560;
}

.was-validated :invalid ~ .select-selected:after {
    border-color: #ee6560;
}

.was-validated :invalid ~ .select-selected::before {
    background: url(../images/pin-red.svg) no-repeat center;
    background-size: cover;
}

a.sss {
    color: #90b52a;
    font-size: 14px;
    position: relative;
    display: block;
}

.sss-collapse {
    color: #46742C;
    font-size: 14px;
    font-weight: normal;
    margin-left: 2em;
    margin-bottom: 1em;
}

a.sss[aria-expanded="true"]::after {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    right: 0;
    top: 6px;
    border-bottom: 2px solid #46742c;
    border-right: 2px solid #46742c;
    transform: rotate(-135deg);
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;

}

a.sss[aria-expanded="false"]::after {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    right: 0;
    top: 6px;
    border-bottom: 2px solid #46742c;
    border-right: 2px solid #46742c;
    transform: rotate(45deg);
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.switch {
    height: 0;
    width: 0;
    visibility: hidden;
}

.switch-toggle {
    cursor: pointer;
    text-indent: -9999px;
    width: 52px;
    height: 24px;
    background: #fff;
    display: inline-block;
    border-radius: 100px;
    position: relative;
    border: solid 1px #90b52a;
}

.switch-toggle:after {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    width: 24px;
    height: 24px;
    background: #fff;
    border-radius: 20px;
    transition: 0.3s;
    border: solid 1px #90b52a;
}

.switch:checked + .switch-toggle {
    background: #90b52a;
    border-color: #90b52a;
}

.switch:checked + .switch-toggle:after {
    left: 27px;
    border-color: #90b52a;
    /* transform: translateX(-50px); */
}
hr {
    border-top: 1px solid rgba(0, 0, 0, 0.04);
    margin-top: 0;
    margin-bottom: 0;
}

.sss span {
    font-size: 14px;
    font-weight: 600;
    color: #90B52A;
    display: inline-block;
}

.switch {
    display: inline-block;
}

.sss {
    margin-bottom: 0.5em;
    margin-top: 0.5em;
}

.modal h1 {
    font-size: 22px;
    font-weight: bold;
    color: #46742c;
    text-align: center;
    margin-bottom: 2em;
}

#login form, #signup form {
    max-width: 380px;
    margin: 0 auto;
}


.modal-body {
    padding: 4em 1em 2em 1em;
    /*padding: 4em 2em 2em 2em;*/
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 25px;
    outline: 0;
}


.close-modal {
    background: #84b700;
    width: 40px;
    height: 40px;
    border-radius: 25px;
    box-shadow: 0 0 18px 0 rgb(0 0 0 / 32%);
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    z-index: 1;
}

.close-modal i::after, .close-modal i::before {
    content: "";
    position: absolute;
    background: white;
    width: 2px;
    height: 15px;
    left: 19px;
    top: 13px;
    transform: rotate(45deg);
}

.close-modal i {
    display: block;
}

.close-modal i::before {
    transform: rotate(-45deg);
}

.close-modal:hover {
    background-color: #A6C35F;
}

p.kvkk {
    font-size: 12px;
    text-align: center;
    color: #84b700;
    margin-top: 2em;
    margin-bottom: 2.6em;
}

#login p.kvkk a, #signup p.kvkk a {
    color: #46742c;
    cursor: pointer;
}

#login p, #signup p {
    font-size: 12px;
    font-weight: 600;
    text-align: center;

    margin-bottom: 1.5em;
}

#login p a, #signup p a {
    color: #46742c;
}

#signup .form-group.form-check {
    margin-bottom: 1em;
}

.form-group label.form-check-label {
    position: relative;
    cursor: pointer;
}

.form-group label.form-check-label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #46742c;
    border-radius: 4px;
    /*box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);*/
    padding: 6px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
}

.form-group input:checked + label.form-check-label:after {
    content: '';
    display: block;
    position: absolute;
    top: 6px;
    left: 4px;
    width: 8px;
    height: 8px;
    background: #90b52a;
    border-radius: 10px;
}

/*RADÄ°O*/
.form-radio-input {
    display: none;
}

.form-group label.form-radio-label {
    position: relative;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
}

.form-group label.form-radio-label:after {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #46742c;
    border-radius: 4px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 6px;
    display: block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    /* margin-right: 5px; */
    /* top: 0; */
    /* left: 0; */
    /* right: 0; */
    /* bottom: 0; */
    width: 8px;
    margin: 5px auto;
}

.form-group input:checked + label.form-radio-label:after {
    content: '';
    display: block;
    position: relative;
    /* top: 6px; */
    /* left: 4px; */
    width: 8px;
    height: 8px;
    background: #90b52a;
    border-radius: 4px;
}

.form-check-input {
    display: none;
}

#forgotpass h2, #confirmpass h2, #newpass h2 {
    font-size: 24px;
    font-weight: bold;
    color: #46742c;
    text-align: center;
    margin-bottom: 0.5em;
}

#forgotpass p, #confirmpass p {
    font-size: 16px;
    color: #90b52a;
    text-align: center;
    margin-bottom: 2em;
}

#forgotpass form, #confirmpass form, #newpass form {
    max-width: 320px;
    margin: 0 auto;
}

.modal-warning .modal-body {
    padding: 4em 2em 3em 2em;
}

.modal-warning h1 {
    font-size: 24px;
    font-weight: bold;
    color: #46742c;
    margin-bottom: 1em;
}

p
.modal-warning p {
    font-size: 16px;
    font-weight: normal;
    color: #90b52a;
}

.subject .select-selected {
    padding: 15px 20px;
}

.single-seller h1 {
    font-size: 32px;
    font-weight: bold;
    color: #46742c;
    margin-bottom: 16px;
    text-align: left;
}

.single-seller p {
    font-size: 16px;
    font-weight: normal;
    color: #46742c;
}

.single-seller {
    margin-bottom: 3em;
}
.clerk-sub-container{
    /*padding-left: 50px;*/
}

@media screen and (max-width: 768px) {
    .single-seller h1 {
        font-size: 22px;
    }

    .single-seller p {
        font-size: 14px;
    }

    .single-seller {
        border-radius: 25px;
        box-shadow: 0 0 16px 0 rgb(0 0 0 / 16%);
        height: auto;
        width: 100%;
        margin-bottom: 2em;
        margin-left: auto;
        margin-right: auto;
        padding: 20px 15px;
    }
}

.seller-min-price {
    border: solid 2px #90b437;
    border-radius: 12px;
    font-size: 14px;
    color: #46742c;
    margin-top: 20px;
    padding: 2px;
}

.seller-min-price span {
    font-weight: bold;
    color: #84b700;
}

.product-modal .title {
    font-size: 26px;
    font-weight: bold;
    color: #46742c;
}

.product-modal .modal-body {
    padding: 3em 2em 2em 2em;
    margin-right: 15px;
    margin-left: 15px;
}

.product-modal .price {
    font-size: 30px;
    font-weight: bold;
    color: #90b52a;
    margin-bottom: 0.5em;
    text-transform: capitalize;
}

.product-modal h1 {
    margin-bottom: 1em;
    text-align: left;
    margin-top: 0.6em;
}

.product-modal p {
    font-size: 16px;
    color: #46742c;
}

.rating span::before {
    background: #83b700;
    display: inline-block;
    width: 8px;
    height: 12px;
    border-radius: 50px 50px 0 0;
    -webkit-transform: rotate(-45deg);
    position: absolute;
    content: '';
    top: 0;
    right: 0;
}
.selected-rating span::before{
    background: white;
}

.rating span::after {
    background: #83b700;
    border-radius: 50px 50px 0 0;
    -webkit-transform: rotate(49deg);
    content: '';
    position: absolute;
    top: 0px;
    width: 8px;
    height: 12px;
    right: -3px;
}
.selected-rating span::after{
    background: white;
}


.rating {
    position: relative;
    text-align: right;
}

.rating span {
    display: inline-block;
    width: 10px;
    height: 10px;
    position: relative;
    margin-left: 5px;
}

.rating span.grey::after, .rating span.grey::before {
    background: #b1b1b1;
}

.rating i.points {
    font-size: 12px;
    font-weight: bold;
    color: #46742c;
    display: inline-block;
    margin-left: 5px;
    font-style: normal;
}

.products .size .minus-item, .products .size .plus-item {
    background-color: #fd7922;
    width: 32px;
    height: 32px;
    padding: 0;
    border: none;
    color: #fff;
    border-radius: 8px;
}

.products .size .item-count {
    margin: 0;
    min-height: 32px !important;
    height: 32px;
    padding: 0;
    border: 0;
    text-align: center;
    background: #fd7922;
    border-radius: 8px;
    resize: none;
    color: #fff;
    text-indent: 10px;
    -webkit-text-fill-color: #fff;
    -webkit-opacity: 1;
}

.products .size {
    background: #fd7922;
    border-radius: 8px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    opacity: 0;

}
.description h5{
    font-size: 18px;
    font-weight: bold;
    color: #46742c;
    margin-bottom: 1em !important;
}
.product-modal .size.input-group {
    border-radius: 12px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
    border: 1px solid #fefffa;
    background: #fff;
    height: 50px;
    /*margin-bottom: 2em;*/
}

.product-modal .minus-item-modal, .product-modal .plus-item-modal {
    background: transparent;
    border: none;
    outline: none;
    box-shadow: none;
}

.product-modal input.item-count-modal {
    background: #fff;
    border: none;
    text-align: center;
    text-indent: 10px;
    height: 50px;
    min-height: 50px;
    color: #90B52A;
    font-weight: bold;
    font-size: 18px;
    padding: 0;
    -webkit-text-fill-color: #90B52A;
    -webkit-opacity: 1;
}

@media screen and (max-width: 768px) {
    .product-modal input.item-count-modal {
        text-indent: 0;
    }

    .product-modal .modal-body {
        padding: 3em 1em 2em 1em;
    }
}

.products .size .minus-item, .products .size .item-count {
    opacity: 0;
}

.products .size {
    background: #fff;
}

button.plus-item.input-group-addon {
    background-image: url(../images/plus.svg);
    background-repeat: no-repeat;
    background-position: center;
}

button.minus-item.input-group-addon {
    background-image: url(../images/minus.svg);
    background-repeat: no-repeat;
    background-position: center;
}

.js .animate-box {
    opacity: 0;
}

#map {
    height: 390px;
    background: aliceblue;
    width: 100%;
}

.scrollable-menu {
    max-height: 400px;
    overflow-x: hidden;
    width: 100%;
}
.scrollable-menu-markets {
    padding: inherit;
    width: inherit;
    max-height: 12rem;
    overflow-y: scroll;
    background: white;
}
.featured-blog-title{
    font-family: "Open Sans", sans-serif;
    font-weight: bold;
    color: #84b700;
    font-size: 24px;
}
.scrollable-menu-markets::-webkit-scrollbar{
    width: 10px;
}
.scrollable-menu-markets::-webkit-scrollbar-track{
    background: #d7e5ba;
    border: 4px solid white; /* Use your background color instead of White */
    background-clip: content-box;
    margin-top: 15px;
    margin-bottom: 15px;
}
.scrollable-menu-markets::-webkit-scrollbar-thumb{
    background: #90b437;
    border-radius: 9px;
}

#loader .modal-content{
    display: contents;
}

.dropdown-holder {
    border-radius: 16px;
    background: white;
    height: 90px;
    padding: 1.4em;
    position: absolute;
    top: -28px;
    left: 0;
    right: 0;
    z-index: 1;
    bottom: 0;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.map-dropdown a.dropdown-toggle {
    color: #84b700;
    font-size: 16px;
    width: 100%;
    display: block;
}

.map-dropdown {
    /*border-right: 1px solid #46742c;*/
    padding-top: 10px;
    padding-bottom: 10px;
}

.map-dropdown:last-child {
    border: none;
}

.map-dropdown .dropdown-toggle::after {
    position: absolute;
    right: 0;
    margin-top: 5px;
}

.map-dropdown .dropdown-toggle::after {
    /*border-color: #84b700;*/
    margin-left: 0;
    right: 20px;
    margin-top: 7px;
}

i.grey-pin {
    background: url(../images/menu-pin.svg) no-repeat center;
    width: 15px;
    height: 15px;
    display: inline-block;
    background-size: contain;
    margin-right: 9px;
}

.map-dropdown .dropdown-menu.dropdown-menu.scrollable-menu {
    width: 100%;
    /*margin-left: -22px !important;*/
    /*top: 33px !important;*/
}

.map-dropdown a.dropdown-item {
    border: none;
    text-transform: uppercase;
    padding: 0.35rem 0.2rem;
}

@media screen and (max-width: 768px) {
    .dropdown-holder {
        width: 97%;
    }

    .map-dropdown a.dropdown-toggle {
        font-size: 14px;
    }
}

.map-dropdown:last-child .dropdown-menu {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.map-dropdown:first-child .dropdown-menu {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.map-dropdown:nth-child(2) .dropdown-menu {
    border-radius: 0;
}

.map-dropdown ::-webkit-scrollbar, #comments ::-webkit-scrollbar, .all-basket ::-webkit-scrollbar, .order-basket ::-webkit-scrollbar, .custom-select_ ::-webkit-scrollbar {
    width: 10px;
}

.map-dropdown ::-webkit-scrollbar-track, #comments ::-webkit-scrollbar-track, .all-basket ::-webkit-scrollbar-track, .order-basket ::-webkit-scrollbar-track, .custom-select_ ::-webkit-scrollbar-track {
    background: #90b4375c;
    border: 4px solid white; /* Use your background color instead of White */
    background-clip: content-box;
    margin-top: 15px;
    margin-bottom: 15px;
}

.map-dropdown ::-webkit-scrollbar-thumb, #comments ::-webkit-scrollbar-thumb, .all-basket ::-webkit-scrollbar-thumb, .order-basket ::-webkit-scrollbar-thumb, .custom-select_ ::-webkit-scrollbar-thumb {
    background: #90b437;
    border-radius: 9px;
}

.map-dropdown ::-webkit-scrollbar-thumb:hover, #comments ::-webkit-scrollbar-thumb:hover, .all-basket ::-webkit-scrollbar-thumb:hover, .order-basket ::-webkit-scrollbar-thumb:hover, .custom-select_ ::-webkit-scrollbar-thumb:hover {
    background: #9AD600;
}

.map-dropdown ::-webkit-scrollbar-track {
    margin: 0;
}

.modal-open .modal {
    overflow-y: scroll;
}

.gm-style img {
    border-radius: 6px;
    height: 50px;
    /*    -webkit-transition: 0.5s;
        -o-transition: 0.5s;
        transition: 0.5s;*/
}

.gm-style .gm-style-iw {
    color: #90b437;
    font-weight: bold;
    text-align: left;
    font-size: 15px;
}

.gm-style .gm-style-iw p {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0;
    margin-left: 10px;
}

#market-places span {
    display: inline-block;
    vertical-align: text-bottom;
    color: #90B52A;
    float: right;
}

#market-places p {
    color: #46742C;
    font-size: 16px;
    font-weight: bold;
    display: inline-block;
    position: relative;
    margin-bottom: 0;
    vertical-align: middle;
    left: 10px;
    margin-top: 0.8em;
}

#market-places p::before {
    content: "";
    position: absolute;
    background: url(../images/market.svg) no-repeat center;
    width: 16px;
    height: 16px;
    display: inline-block;
    background-size: contain;
    left: -30px;
    top: 5px;
}

#market-places .places {
    border-top: 1px solid #90b52a4d;
    padding-top: 0.8em;
    padding-bottom: 0.8em;
}

#market-places h1 {
    font-size: 24px;
    font-weight: bold;
    color: #46742c;
    margin-bottom: 1.5em !important;
}

#comments .modal-body .content {
    max-height: 450px;
    overflow-y: scroll;
    margin: 0 auto;
    padding-right: 15px;
}

#comments h1 {
    font-size: 22px;
    font-weight: bold;
    color: #46742c;
    text-align: center;
    margin-bottom: 1.5em !important;
}

#comments .username {
    color: #46742c;
    font-weight: bold;
    font-size: 18px;
}

#comments .username::before {
    content: "";
    position: relative;
    background: url(../images/user-icon.svg) no-repeat center;
    width: 20px;
    height: 20px;
    display: inline-block;
    background-size: contain;
    top: 3px;
    margin-right: 10px;
}

.user-comment {
    color: #46742c;
    font-size: 14px;
    position: relative;
}

.user-comment::before {
    border-top: 1px solid #90b52a26;
    content: "";
    display: block;
    position: absolute;
    top: -7px;
    width: 100%;
}

.seller-story {
    width: 80%;
    top: 5em;
    margin: 0 auto;
}

.seller-story .slick-slide img {
    height: 500px;
}

.product-modal .plus-item-modal.input-group-addon {
    background-image: url(../images/green-plus.svg);
    background-repeat: no-repeat;
    background-position: center;
    width: 30px;
    height: 100%;
    vertical-align: middle;
}

.product-modal .minus-item-modal.input-group-addon {
    background-image: url(../images/green-minus.svg);
    background-repeat: no-repeat;
    background-position: center;
    width: 35px;
    height: 100%;
}

.addresses {
    padding: 0;
}

.addresses .dropdown-toggle {
    border: 1px solid #90b437;
    border-radius: 15px;
    padding: 10px 15px;
    position: relative;
    display: block;
}

.addresses .dropdown-toggle::after {
    position: absolute;
    right: 20px;
    top: 19px;
}

.addresses .dropdown-menu {
    width: 100%;
}



.addresses a::before {
    background: url(../images/pin-green.svg) no-repeat center;
    background-size: contain;
    content: "";
    display: inline-block;
    position: relative;
    top: 3px;
    width: 20px;
    height: 20px;
    margin-right: 9px;
}

.addresses .new-address::before {
    background: url(../images/add-button.svg) no-repeat center;
    background-size: contain;
    content: "";
    display: inline-block;
    position: relative;
    top: 3px;
    width: 20px;
    height: 20px;
    margin-right: 9px;
}

.addresses button::before {
    background: url(../images/pin-white.svg) no-repeat center;
    background-size: contain;
    content: "";
    display: inline-block;
    position: relative;
    top: 3px;
    width: 20px;
    height: 20px;
    margin-right: 9px;
}

@media screen and (max-width: 768px) {
    .addresses {
        padding: 0 15px !important;
    }

    .addresses .dropdown-menu {
        width: 90%;
    }
}

.orders h1 {
    font-size: 18px;
    font-weight: bold;
    color: #46742c;
}

.orders p {
    font-size: 16px;
    font-weight: 600;
    color: #90b52a;
    padding-bottom: 10px;
    margin-bottom: 0.5em;
}

.orders span.price {
    font-size: 24px;
    font-weight: bold;
    color: #90b52a;
    display: block;
    padding-bottom: 0.5em;
    margin-bottom: 1em;
}

.orders .order-status {
    font-size: 12px;
    font-weight: bold;
    color: #46742c;
    margin: auto 0 auto 0;
}

.btn-arrow {
    padding: 15px;
    background: #90b437;
}

.btn-arrow i {
    background: url(../images/backward-arrow.svg) no-repeat center;
    background-size: contain;
    width: 20px;
    height: 20px;
    display: block;
}

.btn-arrow.in-progress {
    background-color: #eac600;
}

/*Check*/

.check-icon {
    background: #90b437;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;

}

.in-progress {
    background: #eac600;
}

span.icon-line {
    width: 1px;
    display: block;
    height: 5px;
    background: white;
    position: absolute;

}

span.icon-line.line-tip {
    transform: rotate(-45deg);
    top: 10px;
    left: 6px;
}

span.icon-line.line-long {
    top: 6px;
    right: 8px;
    transform: rotate(45deg);
    height: 9px;
}

.align-arrow {
    margin-top: auto;
    margin-bottom: 1.5em;
    text-align: right;
}

/*Check*/
.orders .btn-primary {
    margin-bottom: 1em;
}

@media screen and (max-width: 768px) {
    .orders .order-status {
        margin-bottom: 2em;
    }

    .align-arrow {
        position: relative;
        right: 0;
        bottom: 0;
    }

    .align-arrow .btn-arrow {
        position: absolute;
        right: 0;
        bottom: 0;
    }
}

#myOrders.modal-dialog {
    max-width: 780px;
}

#myOrders .all-basket {
    box-shadow: none;
    padding-top: 0;
}

#myOrders .basket img {
    max-width: 100%;
}

#myOrders .order-summary::after {
    display: none;
}

#myOrders .order-number-tag {
    padding-bottom: 1em;
}

.green-box {
    border: 2px solid #90B437;
    border-radius: 25px;
    margin: 0 auto 2em auto;
    padding: 25px
}

#myFeedback p, .green-box p {
    color: #46742c;
    font-size: 14px;
    font-weight: bold;
}

#myFeedback .rating {
    margin: auto;
    direction: rtl;
    text-align: center;
}

#myFeedback .rating:not(:checked) > input {
    display: none;
}

#myFeedback #rate {
    top: -65px;
}

#myFeedback #rate:not(:checked) > label {
    cursor: pointer;
    float: right;
    width: 30px;
    height: 30px;
    display: block;
    color: rgba(0, 135, 211, .4);
    line-height: 33px;
    text-align: center;
}

#myFeedback #like:not(:checked) > label {
    width: 36px;
    height: 30px;
    display: inline-block;
    position: relative;
    cursor: pointer;
}

#myFeedback #like:not(:checked) > label::before {
    background: #83b700;
    width: 15px;
    height: 24px;
    border-radius: 50px 50px 0 0;
    -webkit-transform: rotate(-45deg);
    position: absolute;
    content: '';
    top: 0;
    left: 0;
}

#myFeedback #like:not(:checked) > label::after {
    background: #83b700;
    border-radius: 50px 50px 0 0;
    -webkit-transform: rotate(45deg);
    content: '';
    position: absolute;
    top: 0px;
    width: 16px;
    height: 20px;
    right: 13px;
}

#myFeedback #like label {
    position: relative;
    display: inline-block;
}

#myFeedback #like:not(:checked) > label::after, #myFeedback #like:not(:checked) > label::before {
    background: #b1b1b1;
}

#myFeedback #like > input:checked ~ label::after, #myFeedback #like > input:checked ~ label::before {
    background: #83b700;
}

#myFeedback #feedback-message {
    border: 0;
    padding: 0;
    width: 100%;
    border-radius: 0;

}

#feedback-message::-webkit-input-placeholder {
    color: #46742c !important;
    opacity: 1;
    font-size: 14px;
    font-weight: bold;
}

#feedback-message::-moz-placeholder {
    color: #46742c;
    opacity: 1;
}

#feedback-message:-ms-input-placeholder {
    color: #46742c;
    opacity: 1;
}

#feedback-message::-ms-input-placeholder {
    color: #46742c;
    opacity: 1;
}

#feedback-message::placeholder {
    color: #46742c;
    opacity: 1;
}

#warning p, #deleteWarning p, .modal-warning p {
    font-size: 16px;
    font-weight: normal;
    color: #90b52a;
    text-align: center;
}

.summary .box {
    min-height: 260px;
    margin-bottom: 0;
    padding-top: 1.5em;
    padding-bottom: 1.5em;
}
.summary .promotion {
    min-height: 60px;
    margin-bottom: 0;
}
.promotion .form-group {
    margin-bottom: 0;
}
.order-note{
    margin-bottom: 0;
}
.order-summary-page .all-basket{

}

@media screen and (min-width: 576px) {
    .modal-warning .modal-dialog {
        max-width: 420px;
    }

}

.all-basket i {
    display: none;
}

.all-basket h3 {
    display: none;
}

@media screen and (max-width: 768px) {
    .addbasket {
        max-height: 330px;
        overflow-y: auto;
    }

    .markets .all-basket {
        position: fixed;
        width: 95%;
        bottom: 0;
        left: 0;
        right: 0;
        background: #fd7922;
        min-height: 70px;
        z-index: 99999;
        margin: 10px auto;
        padding: 10px 14px;
        border-radius: 12px;
        max-height: 590px;
    }

    .markets .all-basket .addbasket, .all-basket .basket-sum {
        display: none;
        clear: both;
    }

    .markets .all-basket h2 {
        color: #fff;
        text-align: left;
        margin-bottom: 2em;
        text-transform: uppercase;
        padding-left: 15px;
        padding-right: 15px;
        width: 100%;
        font-size: 16px;
        position: relative;
        top: 10px;
        font-weight: bold;
    }
    .order-summary-page
    .mobile-basketsum {
        display: none;
    }
    .order-summary-page
    .container {
        margin-top: 4rem;
    }

    .all-basket h3 {
        color: #fff;
        display: block;
        position: relative;
        top: 15px;
        left: 10px;
        margin-bottom: 0;
        padding-left: 15px;
        padding-right: 15px;
        font-size: 16px;
        text-transform: uppercase;
        font-weight: bold;
    }

    .all-basket h2::after {
        display: none;
    }

    .all-basket i {
        font-style: normal;
        display: inline-block;
        text-align: right;
        float: right;
        background-color: #fff;
        border-radius: 10px;
        color: #46742c;
        padding: 16px 28px;
        position: relative;
        top: -15px;
        right: -10px;
        font-size: 20px;
        font-weight: bold;
    }

    .mobile-basket {
        position: fixed;
        width: 100%;
        bottom: 0;
        left: 0;
        right: 0;
        background: white;
        height: 90px;
        box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.16);
        z-index: 10;
    }

    .all-products .right {
        display: none;
    }

    .summary .box {
        min-height: auto;
    }
}

.basket-conditions {
    font-size: 12px;
    font-weight: 600;
}

.basket-conditions a {
    color: #90b52a;
    font-weight: bold;
}

p.secure-shopping {
    color: #46742c;
    font-size: 16px;
    font-weight: bold;
}

p.secure-shopping::before {
    content: "";
    display: inline-block;
    background: url(../images/lock.svg) no-repeat center;
    width: 17px;
    height: 21px;
    position: relative;
    top: 4px;
    margin-right: 10px;
    background-size: cover;
}

.basket-info .form-group {
    margin-bottom: 1em;
}

.address-summary .order-summary .border-line {
    margin-bottom: 0.5em;
    margin-top: 0.5em;
    width: 100%;
}

.address-summary p.order-time {
    color: #46742c;
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 0.4em;
}

h2.order-date {
    font-size: 22px;
    font-weight: bold;
}

.radio-btns .form-group {
    margin-bottom: 0;
}

.btn-comment-stall{
    margin-left: 25px;
}

@media screen and (min-width: 768px) {
    .google-map .dropdown-toggle br {
        display: none;
    }

    .comments-btn {
        margin-bottom: 1em !important;
    }

    .google-play {
        margin-left: 15px;
    }

}

.blog-content-img{
    max-width: 32rem;
    border-radius: 12px;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 16px;
    float: right;
}

.blog-bottom-blogs-mobile{
    display: none;
}
.all-blogs-mobile{
    display: none;
}
@media screen and (max-width: 768px) {
    .sub-header{
        display: none;
    }
    .all-blogs-desktop{
        display: none;
    }
    .all-blogs-mobile{
        display: block;
    }
    .blog-bottom-blogs-desktop{
        display: none;
    }
    .all-blogs-content{
        display: flex;
        flex-direction: column;
    }
    .blog-content-img{
        max-width: 16rem;
        border-radius: 12px;
        margin-left: 16px;
        margin-right: 16px;
        margin-bottom: 16px;
        float: right;
    }
    .btn-comment-stall{
        margin-left: 0;
        margin-right: 0;
        margin-top: 1rem;
    }
    .footer p {
        margin-right: auto;
        margin-left: auto;
    }
    .clerk-sub-container{
        padding-left: 15px;
    }
    .top-img-mobile{
        margin-top: 60px;
    }
    .blog-bottom-blogs{
        display: none;
    }
    .blog-bottom-blogs-mobile{
        display: block;
    }
    .blog-bottom-blogs-mobile .row {
        margin-left: 0;
        margin-right: 0;
    }
    .blog-bottom-blogs-mobile .col-12 {
        margin-left: 0;
        margin-right: 0;
    }
    .blog-bottom-blogs-mobile .blog-container {
       padding: 0;
    }
    .blog-bottom-blogs-mobile svg {
        display: none;
    }

}

@media screen and (min-width: 992px){
    .showContent{
        border-radius: 12px 0 0 0!important;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px){

    .download-icons-mobile a{
        margin-left: auto;
        margin-right: auto;
    }
    .socials{
        align-self: center;
    }
    .seller-btn{
        align-self: center;
    }

    .clerk-sub-container{
        padding-left: 15px;
        margin-bottom: 1rem;
    }
    .left-stalls-column {
        padding-left: 15px;
        padding-right: 15px;
    }
    .sub-header {
        height: 70px;
    }
    .show-stalls-btn-header{
        display: none;
    }
    .show-basket-btn-header{
        margin-top: 6px;
        flex: 0 0 50%;
    }
    .btn-comment-stall{
        margin-left: 0;
        margin-right: 0;
    }
    .address-dropdown{
        width: 50%;
        flex: 0 0 50%;
    }
    .footer p {
        margin-right: auto;
        margin-left: auto;
    }
}

.last-orders .order-label {
    background-color: #fd7922;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    padding: 12px 20px;
    border-radius: 12px 12px 0 0;
    line-height: 21px;
    text-align: center;
    max-height: 165px;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.last-orders-container .name {
    font-size: 14px;
    font-weight: bold;
    color: #46742C;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.last-orders-container .address {
    font-size: 12px;
    color: #90B52A;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.last-orders-container .total {
    font-size: 18px;
    font-weight: bold;
    color: #46742C;
}

.last-orders-container {
    text-align: center;
    display: none;
    background-color: #fff;
    padding: 5px 45px;
    max-height: 165px;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    box-shadow: 0 0 16px 0 rgb(0 0 0 / 16%);
    /*border-radius: 0 12px 12px 0;*/
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
.last-orders-container::-webkit-scrollbar {
    display: none;
}

.last-orders-container .item {
    display: inline-block;
    border-right: 1px solid #46742c26;
    padding: 12px 15px;
}

.last-orders {
    position: fixed;
    bottom: 0;
    z-index: 1;
    right: 0;
    left: 0;
}

.last-orders .order-label span {
    display: block;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
}

.order-label-radius {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.last-orders-container .slick-prev:before {
    border-left: 5px solid #46742c;
    border-bottom: 5px solid #46742c;
}

.last-orders-container .slick-next:before {
    content: ' ';
    border-top: 5px solid #46742c;
    border-right: 5px solid #46742c;
}

.last-orders-container .slick-prev {
    left: 0;
}

.last-orders-container .slick-next {
    right: 18px;
}

.markets .points {
    font-size: 38px;
    font-weight: bold;
    color: #46742C;
    text-align: right;
}

.rating_ span::before {
    background: #83b700;
    display: inline-block;
    width: 12px;
    height: 18px;
    border-radius: 50px 50px 0 0;
    -webkit-transform: rotate(-45deg);
    position: absolute;
    content: '';
    top: 0;
    right: 0;
}

.rating_ span::after {
    background: #83b700;
    border-radius: 50px 50px 0 0;
    -webkit-transform: rotate(49deg);
    content: '';
    position: absolute;
    top: 0px;
    width: 12px;
    height: 18px;
    right: -5px;
}

.rating_ {
    position: relative;
    text-align: right;
    top: 1em;
}

.rating_ span {
    display: inline-block;
    width: 18px;
    height: 18px;
    position: relative;
    margin-left: 5px;
}

.rating_ span.grey::after, .rating_ span.grey::before {
    background: #b1b1b1;
}

.market {
    border-radius: 25px;
    box-shadow: 0 0 16px 0 rgb(0 0 0 / 16%);
    padding: 1em;
    margin-bottom: 2em;
    margin-top: 2em;
    background: #fff;
}

@media screen and (max-width: 768px) {
    .market-name {
        margin-top: 0.5em;
    }
}

.featured-blog-box .slick-prev:before {
    border-color: #84B700;
}

.featured-blog-box .slick-next {
    right: 0px;
    top: 10%;
}

.featured-blog-box .slick-prev {
    left: -50px;
    top: 10%;
}

.btn-secondary, .btn-primary, .btn-featured {
    font-weight: 600;
}

.user-conditions a {
    color: #46742c;
    text-decoration: underline;
}

/* Map */
#map {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
}

.marker {
    background-image: url(../images/menu-pin-orange.svg);
    background-size: contain;
    width: 40px;
    height: 40px;
    cursor: pointer;
    background-repeat: no-repeat;
}

.mapboxgl-popup {
    max-width: 272px;
}

.mapboxgl-popup-content h3 {
    font-size: 14px;
    margin-bottom: 0;
}

.mapboxgl-popup-content p {
    font-size: 12px;
    margin-bottom: 0;
}

.mapboxgl-popup-content {
    color: #83b700;
    border-radius: 12px !important;
    text-align: left;
    height: 60px;
}

.mapboxgl-popup-content img {
    margin-right: 10px;
    float: left;
}

.mapboxgl-popup-content .r-box {
    float: right;
}

.mapboxgl-popup-close-button {
    display: none;
}
