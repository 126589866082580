.col-new{
    margin-right: 5px;
    margin-left: 5px;
}
.left-stalls{
    margin-right: -13px;
    margin-left: -13px;
    max-height: 86vh;
    overflow-y: scroll;
    overflow-x: hidden;
}
.left-stalls::-webkit-scrollbar{
    width: 10px;
}
.left-stalls::-webkit-scrollbar-track{
    background: #d7e5ba;
    border: 4px solid white; /* Use your background color instead of White */
    background-clip: content-box;
    margin-top: 15px;
    margin-bottom: 15px;
}
.left-stalls::-webkit-scrollbar-thumb{
    background: #90b437;
    border-radius: 9px;
}
@media only screen and (max-width: 768px){
    .left-stalls{
        flex-direction: column;
        align-items: center;
    }
}


@media only screen and (min-width: 768px) and (max-width: 991px){
    .left-stalls{
        flex-direction: column;
        align-items: center;
    }
}
